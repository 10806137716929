import { ReactQueryClientProvider } from '@/ReactQueryClientProvider';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { PlatformManagementClientProvider } from '@gik/platform-management-api-react/contexts';
import type { IPageLayoutAttachment } from '@gik/ui/gik/AppLayout/AppLayout';
import { AppLayout } from '@gik/ui/gik/AppLayout/AppLayout';
import React from 'react';

export type PageLayoutProps = {
  className?: string;
} & IPageLayoutAttachment;

export function PageLayout({
  navBarAttachment,
  footerAttachment,
  children,
  className,
}: React.PropsWithChildren<PageLayoutProps>): React.ReactElement {
  return (
    <ReactQueryClientProvider>
      <PlatformManagementClientProvider
        baseUrl={useEnvStore.getState().PLATFORM_API_BASE_URL}
        apiKey={useEnvStore.getState().PLATFORM_API_KEY}
      >
        <AppLayout className={className} navBarAttachment={navBarAttachment} footerAttachment={footerAttachment}>
          {children}
        </AppLayout>
      </PlatformManagementClientProvider>
    </ReactQueryClientProvider>
  );
}
